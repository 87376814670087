<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/scheme/banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <el-row :gutter="100">
                <el-col :span="5" :xs="24" style="background:#ffffff;margin-top:20px;padding:0px !important;padding-top:20px;padding-bottom:20px;">
                    <el-tabs v-model="class_id" :tab-position="tabPosition"  :class=" $i18n.locale == 'en' || $i18n.locale == 'pt' ? '' : 'tabclass'" @tab-click='tabclick'>
                        <el-tab-pane name='0' :label="this.$t('all')"></el-tab-pane>
                        <el-tab-pane :label="item.class_name" v-for="(item,index) in classList" :name="item.id+''" :key="index"></el-tab-pane>
                    </el-tabs>
                </el-col>
                <el-col :span="19" :xs="24">
                    <div class="content">
                        <div class="chanpinlist">
                            <el-row :gutter="20">
                                <el-col :span="8" :xs="12" v-for="(item,index) in list" :key="index">
                                    <div style="background:#FFF;padding-bottom:5%;cursor: pointer;margin-bottom:5%" @click="nav('scheme/'+item.scheme_id)">
                                        <div class="chanpinimage">
                                            <el-image style="width: 100%;" :src="item.image"></el-image>
                                        </div>
                                        <div class="chanpintitle">
                                            {{item.scheme_title}}
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-pagination
                                v-show="total>schemeParams.limit"
                                style="margin-top:3%;text-align:center"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="schemeParams.page"
                                :page-sizes="[12, 21, 36, 45]"
                                :page-size="12"
                                layout="total, prev, pager, next"
                                :pager-count="5"
                                :total="total">
                            </el-pagination>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import { schemeClass,schemeList} from "@/api/api"; 
export default {
    data(){
        return{
            checkboxGroup4: [this.$t('all')],
            tabPosition:'right',
            classList:[],
            schemeParams:{
                class_id:'',
                page:1,
                limit:12
            },
            total:0,
            list:[],
            class_id:'',
            title:''
        }
    },
    created(){
        this.title = this.$t('layout.solution')+'-'+this.$t('seo.indexkeywords')
        let class_id = this.$route.query.class_id
        if(class_id != undefined){
            this.class_id = class_id+''
            this.schemeParams.class_id = class_id
        }
        this.getClass()
    },
    mounted() {
        if (this._isMobile()) {
            this.tabPosition = 'top'
        } else {
            this.tabPosition = 'right'
        }
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.indexkeywords')
            },
            {
                name: 'description',
                content: this.$t('seo.indexdescription')
            }
        ]
        }
    },
    methods:{
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        tabclick(data){
            if(data.index == 0){
                this.schemeParams.class_id = 0
            }else{
                this.schemeParams.class_id = this.classList[data.index-1].id
            }
            this.getList()
        },
        async getClass(){
            const {data:res} = await schemeClass()
            this.classList = res
            this.getList()
        },
        async getList(){
            const {data:res} = await schemeList(this.schemeParams)
            res.list.forEach((item)=>{
                item.image = process.env.VUE_APP_BASE_API+item.scheme_image
            })
            this.total = res.total
            this.list = res.list
            history.pushState(null, null, "/schemeinfo?class_id="+this.schemeParams.class_id)
        },
        checkboxchang(data){
            if(data.length == 0){
                this.checkboxGroup4 = [this.$t('all')]
                return
            }
            if(data.includes(this.$t('all'))){
                if(data[data.length-1] == this.$t('all')){
                    this.checkboxGroup4 = [this.$t('all')]
                }else{
                    if(data.length>1){
                        data.splice(data.indexOf(this.$t('all')),1);
                        this.checkboxGroup4 = data
                    }
                }
            }
        },
        nav(item){
            this.$router.push('/'+item,'_blank')
        },
        handleSizeChange(){

        },
        handleCurrentChange(){

        },
    }
}
</script>
<style lang="scss" scoped>
    @media screen and (max-width: 480px) {
        .appmain{
            .tabclass{
                height: 100%;
                width: 100%;
            }
        }
    }
    .tabclass{
        height: 100%;
        width:210px;
    }
    .tabclass >>> .el-tabs__active-bar{
        padding-left:3px;
        height: 0px !important;
        margin-top:15px;
        background: #0052D9;
    }
    .content{
        margin-top: 20px;
        .chanpinlist{
            .chanpinimage{
                
            }
            .chanpintitle{
                text-align: center;
                margin-top: 20px;
                font-size: 16px;
                font-weight: bold;
                color: #333333;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
</style>
<style scoped>
    /deep/ .el-tabs__item.is-active{
        color: #0052D9;
    }
    /deep/ .el-tabs__item:hover{
        color: #0052D9;
    }
    /deep/ .el-tabs__active-bar{
        padding-left:3px;
        height: 0px !important;
        margin-top:15px;
         background: #0052D9;
    }
    /deep/ .el-tabs__nav-wrap::after {
        position: static !important;
        height: 0;
    }
    /deep/ .el-tabs__item{
        font-weight: bold;
        height: 50px;
        line-height: 50px;
    }
    /deep/ .el-checkbox-button__inner{
        border: none;
    }
    /deep/ .el-checkbox-button:first-child .el-checkbox-button__inner{
        border: none;
    }
    /deep/ .el-checkbox-button, .el-checkbox-button__inner{
        margin-right: 20px;
    }
    /deep/ .el-checkbox-button__inner{
        font-size: 15px;
    }
</style>